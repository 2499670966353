import { HVitaSimulationStudentService } from "hvita-simulation";
import { HVitaSocketService } from "./hvita-socket.service";
import { HVitaConfigService } from "./hvita-config.service";
import { EventManagerService } from "event-manager";
import { ErrorType } from "hybrids-config";
import { HVitaParameterService } from "./hvita-parameter.service.";
import { HVitaTemplateService } from "./hvita-template.service";
import { HVitaSimulationSelectorService } from 'hvita-simulation-selector';
import { Parameter, Test } from 'hvita-common';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { SocketAction } from 'hybrids-socket';
import { DialogBoxService } from 'dialog-box';
import { ModalBoxService } from 'modal-box';
import { HVitaScenarioEvent } from "hvita-scenario";
import { PatientMonitorService } from "./patient-monitor.service";
import { PatientMonitorEvent } from "patient-monitor";

/**
 * Gestiona los aspectos generales de la simulacion
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaSimulationService extends HVitaSimulationStudentService
{

  constructor(eventManagerService: EventManagerService,
              configService: HVitaConfigService,
              socketService: HVitaSocketService,
              templateService:HVitaTemplateService,
              parameterService:HVitaParameterService,
              selectorService:HVitaSimulationSelectorService,
              dialogService:DialogBoxService,
              modalBoxService:ModalBoxService,
              private monitorService:PatientMonitorService,
              private router:Router)
  {
    super(eventManagerService, configService, socketService, templateService, parameterService, dialogService, modalBoxService, selectorService);
  }

  override initialize()
  {
    super.initialize();

    this.eventManagerService.subscribeEvent(HVitaScenarioEvent.SCENARIO_LOADED, () =>
    {
      if(this.socketService.redirect && !this.selectorService.simulationView)
        this.router.navigate(['/', 'simulation']);
      else
        this.simulationReady().then(() => this.eventManagerService.emitEvent(PatientMonitorEvent.CHANGE_VIEWS_LIST));
    });
  }

  simulationReady()
  {
    return new Promise<void>((resolve) =>
    {
      this.socketService.sendAndRetrive(SocketAction.RHYTHMS_LIST, this.socketService.addresses.teacher).then((rhythms:any) =>
      {
        this.monitorService.rhythms = rhythms.data.rhythms;
        this.monitorService.rhythmsOptions = rhythms.data.options;
        this.monitorService.selectRhythm = rhythms.data.selected;

        this.socketService.sendAndRetrive(SocketAction.PATIENT_VIEW_LIST, this.socketService.addresses.teacher).then((views:any) =>
        {
          this.monitorService.views = views.data.views;
          this.monitorService.selectViews = views.data.selected;

          resolve();
        });
      });
    });
  }

  simulationNotReady()
  {
    if(this.socketService.redirect)
    {
      const route = [ '/', 'error', ErrorType.TEACHER ];

      this.router.navigate(route);
    }
  }

  getTestList() : Promise<Test[]>
  {
    return this.socketService.sendAndRetrive(SocketAction.TEST_LIST, this.socketService.addresses.teacher);
  }
}
