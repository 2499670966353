import { Injectable } from "@angular/core";
import { HybridsSocketService, SocketAction, SocketAddress, SocketResult } from "hybrids-socket";
import { Network } from "network";

/**
 * Servicio que gestiona el socker a traves con el SocketService
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaSocketService extends HybridsSocketService
{
    /**
   * Establece conexión con el socket
   */
    override openConnection()
    {
      Network.getNetworkStatus().then(status =>
      {
        if(status)
          super.openConnection();
        else
          this.sendConnectionResult(false);
      });
    }
}
