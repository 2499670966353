import { registerPlugin } from '@capacitor/core';

import type { ExitPlugin } from './definitions';

/**
 * Registra el plugin
 */
const Exit = registerPlugin<ExitPlugin>('Exit', {
  web: () => import('./web').then(m => new m.ExitWeb()),
  electron: () => (window as any).CapacitorCustomPlatform.plugins.Exit,
});

export * from './definitions';
export { Exit };
