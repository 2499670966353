export interface InfoPlugin 
{
  info: InfoVersion;

  /**
   * Comprueba si existe la dll que permite iniciar el programa
   * @returns objeto InfoVersion
   */
  check(name: DllDevice): Promise<InfoVersion>;

  /**
   * Comprueba si existe la dll que permite iniciar el programa
   * @returns boolean
   */
  isProduction(): Promise<boolean>;

  /**
   * Comprueba si existe la dll que permite iniciar el programa
   * @returns boolean
   */
  isDevelop(): Promise<boolean>;
}

export enum DllDevice
{
  WINCO_PRO = "vmswcp",
  WINCO_BASIC = "vmswcb",
  HVITA_TEACHER = "vmshvt",
  HVITA_STUDENT = "vmshvs",
  REPLICA = "vmsrpc"
}

export class InfoVersion
{
  mode: ModeVersion | undefined;
  hardware: string | undefined;
  software: string | undefined;
  ssid: string | undefined;
  password: string | undefined;
  hardwareMode: number | undefined;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export enum ModeVersion 
{
  DEVELOP = 1,
  PRODUCTION = 2
}
