import { Injectable } from "@angular/core";
import { HVitaTemplateBaseService } from "hvita-template";
import { HVitaConfigService } from "./hvita-config.service";
import { Template } from "hvita-common";
import { EventManagerService } from "event-manager";

/**
 * Servicio que controla el sistema de personalización
 * de visualización de parámetros de la simulación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaTemplateService extends HVitaTemplateBaseService
{
  constructor(configService:HVitaConfigService,
              eventManagerService: EventManagerService)
  {
    super('hvita.student.template', configService, eventManagerService);
  }


  /*********** METODOS SOBREESCRITOS ***********/

  /**
   * Carga las plantillas
   * @returns promesa
   */
  override loadTemplates(): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      try
      {
        //comprobamos si existe alguna plantilla guardada en memoria
        const localTemplate = this.getLocalTemplate();

        if(localTemplate)
          this.template = localTemplate; //cargamos la plantilla de la memoria
        else
          this.saveLocalTemplate(false); //guardamos la plantilla por defecto enviada por Teacher

        //creamos la distribución visual de elementos
        //los números de la clave corresponden con los del enumerador SectionType
        this.configService.config.distribution = this.getDistribution();

        resolve();
      }
      catch (err)
      {
        reject();
      }
    });
  }

  setTemplateList(templates:Template[])
  {
    this.templates = templates;
  }

  //se declaran para cumplir con el interfaz de la clase base
  saveTemplate(name:string){}
  removeTemplate(idTemplate:number){}
}
