export interface NetworkPlugin 
{
  wireless:Wireless;
  default:Wireless;
  
  localStorageName: string;
  hasNetwork:boolean;
  stopped:boolean;

  /**
   * Solicita la lista de redes wi-fi al alcance
   * @returns Promise<any> - Devuelve la lista de redes al alcance
   */
  scan(): Promise<any[]>;

  /**
   * Solicita la lista de redes wi-fi a la que el dispositivo está conectado
   * @returns Promise<any> - Devuelve la red actual a la que está conectado el equipo
   */
  current(): Promise<any[]>;

  /**
   * Intenta conectar con una red wi-fi
   * @param ssid ssid
   * @param password contraseña
   * @returns Promise<boolean> - Devuelve el resultado del intento de conexión
   */
  connect(ssid: string, password: string): Promise<any | null>;

  /**
   * Intenta desconectar con una red wi-fi
   * @param ssid ssid
   * @returns Promise<void>
   */
  disconnect(ssid: string): Promise<boolean>;

  /**
   * Obtiene la última red inalámbrica que se haya guardado
   * @returns object Wireless
   */
  getLocalDefault(): Promise<Wireless>;

  /**
  * Almacena la red inalámbrica seleccionada para la aplicación
  * @param wireless object Wireless
  */
  setLocalDefault(wireless: Wireless): Promise<void>;

  /**
  * Inicializa el servicio de wifi
  * @param ssid ssid
  * @param password contraseña
  * @param storageName nombre almacenamiento
  * @returns promesa
  */
  initialize(ssid: string, password: string, storageName: string) : Promise<void>;

  /**
  * Establece una comprobación cíclica del estado de la conexión actual una vez por segundo
  * @returns promesa
  */
  check() : Promise<void>;

  /**
  * Establece una comprobación cíclica una vez por segundo de las redes al alcance e intenta conectarse a la red deseada cuando está disponible
  * @returns promesa
  */
  find() : Promise<void>;

  /**
  * @returns promesa
  * Detiene el scaneo de redes
  */
  stop() : Promise<void>;

  /**
   * Obtiene la red wi-fi
   * @returns objeto Wireless
  */
  getWireless():Promise<Wireless>;

  /**
   * Obtiene la red wi-fi por defecto
   * @returns objeto Wireless
   */
  getDefault():Promise<Wireless>;

  /**
   * Obtiene el estado de la red
   * @returns boolean
   */
  getNetworkStatus():Promise<boolean>;

  /**
   * Comprueba si la red wi-fi está conectada
   * @returns boolean
   */
  isConnected():Promise<boolean>;
}

export class Wireless
{
  ssid: string | undefined;
  password: string | undefined;
  connected: boolean | undefined;
  signal_level: number | undefined; // in dB

  constructor(data: any) {
    Object.assign(this, data);

    this.connected = data.connected === true;
  }
}
