import { Injectable } from '@angular/core';
import { HVitaConfig, HVitaConfigStudentService } from 'hvita-common';


/**
 * Gestiona la configuración global de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaConfigService extends HVitaConfigStudentService
{
  constructor(config: HVitaConfig)
  {
    super(config);

    this.ecmoModeLocked = true;
    this.controllersLocked = false;
    this.teacherEnabled = false;
  }

  /**
   * Inicializa el servicio
   */
  initialize()
  {
  }
}
