import { Injectable } from "@angular/core";
import { MonitorBaseService, PatientMonitorConfig } from "patient-monitor";
import { EventManagerService } from "event-manager";

/**
 * Gestiona las pruebas complementarias que se muestran en una simulación
 */
@Injectable({
  providedIn: 'root'
})
export class PatientMonitorService extends MonitorBaseService
{
  constructor(eventManagerService: EventManagerService,
              config: PatientMonitorConfig)
  {
    super(eventManagerService, config);
  }
}
