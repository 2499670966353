import { registerPlugin } from '@capacitor/core';

import type { NetworkPlugin } from './definitions';

/**
 * Registra el plugin
 */
const Network = registerPlugin<NetworkPlugin>('Network', {
  web: () => import('./web').then(m => new m.NetworkWeb()),
  electron: () => (window as any).CapacitorCustomPlatform.plugins.Network,
});

export * from './definitions';
export { Network };
