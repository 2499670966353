export interface ExitPlugin 
{
  /**
   * Cierra la aplicación
   */
  close(): void;

  /**
   * Apaga el equipo
   */
  shutdown(): void;
}