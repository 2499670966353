import { EventManagerService } from 'event-manager';
import { Injectable } from "@angular/core";
import { HVitaScenarioBaseService } from "hvita-scenario";

/**
 * Gestiona los colores de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaScenarioService extends HVitaScenarioBaseService
{
  constructor(eventManagerService:EventManagerService)
  {
    super(eventManagerService);
  }
}
