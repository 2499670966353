import { Component } from '@angular/core';
import { HybridsMenuEvent } from 'hybrids-menu';
import { Device, ErrorType } from 'hybrids-config';
import { EventManagerService } from 'event-manager';
import { DialogBoxData, DialogBoxService, DialogBoxType } from 'dialog-box';
import { Router } from '@angular/router';
import { HVitaSimulationSelectorService } from 'hvita-simulation-selector';
import { ElectronService } from 'ngx-electron';
import { HVitaIpcService } from 'src/app/services/hvita-ipc.service';
import { HVitaSocketService } from 'src/app/services/hvita-socket.service';
import { ModeVersion } from 'info';
import { VersionBoxService } from 'version-box';
import { HVitaConfigService } from 'src/app/services/hvita-config.service';


/**
 * Muestra el menú y gestiona las funcionalidades ofrecidas en ella.
 */
@Component({
  selector: 'hvita-menu',
  templateUrl: './hvita-menu.component.html',
  styleUrls: ['./hvita-menu.component.scss'],
})
export class HVitaMenuComponent
{
  Device = Device;
  ModeVersion = ModeVersion;

  constructor(private eventService:EventManagerService,
              private selectorService:HVitaSimulationSelectorService,
              private dialogBoxService:DialogBoxService,
              private router:Router,
              public electronService:ElectronService,
              public ipcService:HVitaIpcService,
              private hybridsSocketService:HVitaSocketService,
              private versionBoxService:VersionBoxService,
              private configService:HVitaConfigService)
  { }

  /**
  * Permite la navegación desde el menú lateral de la aplicación
  * @param path nombre de la página a que se quiere navegar
  */
  async navigateTo(path: string)
  {
    this.eventService.emitEvent(HybridsMenuEvent.MENU_CLOSE);

    if(path !== 'help')
    {
      let route = ['/', path];

      if(this.selectorService.simulationPlaying)
      {
        this.dialogBoxService.show(new DialogBoxData({
          type: DialogBoxType.ALERT,
          warning: true,
          message: 'bloqueado_salir_simulacion'
        }));
      }
      else
      {
        if(path === 'home')
        {
          route = (this.configService.teacherEnabled && this.selectorService.simulationPlaying) ?
                    ['/', 'simulation'] :
                    ['/', 'error', ((this.hybridsSocketService.enabledConnection) ? ErrorType.TEACHER : ErrorType.SOCKET).toString()];
        }

        this.router.navigate(route);
      }
    }
  }

  /**
  * Cierra la aplicación desde el menú lateral de la aplicación
  */
  closeApp()
  {
    this.eventService.emitEvent(HybridsMenuEvent.MENU_CLOSE);
    this.ipcService.quitApp();
  }

  /**
  * Abre una ventana modal con la versión de software
  */
  openSoftwareVersion()
  {
    this.eventService.emitEvent(HybridsMenuEvent.MENU_CLOSE);
    this.versionBoxService.show();
  }
}
