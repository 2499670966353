export interface UsbPlugin 
{
  drive: UsbDevice;
  mounted: boolean;

  /**
   * Obtiene el dispositivo de almacenamiento usb conectado al equipo
   * @returns objeto UsbDevice
   */
  device(): Promise<UsbDevice> ;

  /**
   * Obtiene la letra de la unidad del dispositivo actual
   * @returns string
   */
  unit(): Promise<string>;

  /**
   * Comprueba si el dispositivo es una llave maestra
   * @returns boolean
   */
  isMasterKey(): Promise<boolean>;

  /**
   * Comprueba si hay algún dispositivo insertado
   * @returns boolean
   */
  hasDevice(): Promise<boolean>;
}

export class UsbDevice
{
  enumerator: string | undefined;
  path: string | undefined;
  size: number | undefined;
  isMasterKey: boolean | undefined;
  user: string | undefined;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
