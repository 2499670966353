import { Injectable } from '@angular/core';
import { ElectronService } from 'ngx-electron';
import { DllDevice } from 'info';
import { DialogBoxService } from 'dialog-box';
import { HybridsIpcBaseService } from 'hybrids-ipc';
import { HVitaSocketService } from './hvita-socket.service';

/**
 * Gestiona la comunicación con los componentes de Electron
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaIpcService extends HybridsIpcBaseService
{
  constructor(dialogService: DialogBoxService,
              socketService: HVitaSocketService,
              electronService: ElectronService)
  {
    super(DllDevice.HVITA_STUDENT, 'hvita.student.wireless', dialogService, electronService, socketService)
  }

  /**
   * Ejecuta una serie de acciones después de la conexión socket que sobreescriben el método en la clase base
   * Se debe declarar aunque estén vacíos para cumplir con el interface de la clase base abstracta
   */
  protected setNetworkConnectedActions(): void {}

  /**
   * Ejecuta una serie de acciones después de la desconexión socket que sobreescriben el método en la clase base
   * Se debe declarar aunque estén vacíos para cumplir con el interface de la clase base abstracta
   */
  protected setNetworkDisonnectedActions(): void {}

  /**
   * Ejecuta una serie de acciones después de la inicialización del socket que sobreescriben el método en la clase base
   * Se debe declarar aunque estén vacíos para cumplir con el interface de la clase base abstracta
   */
  protected setInitializeDataActions(): void {}
}
