import { registerPlugin } from '@capacitor/core';

import type { UsbPlugin } from './definitions';

/**
 * Registra el plugin
 */
const Usb = registerPlugin<UsbPlugin>('Usb', {
  web: () => import('./web').then(m => new m.UsbWeb()),
  electron: () => (window as any).CapacitorCustomPlatform.plugins.Usb,
});

export * from './definitions';
export { Usb };
