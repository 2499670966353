import { HVitaSocketService } from './hvita-socket.service';
import { Injectable } from "@angular/core";
import { EventManagerService } from "event-manager";
import { HVitaColorToNamePipe } from "hvita-color";
import { HVitaParameterStudentService } from "hvita-parameter";
import { ModalService } from "modal";
import { HVitaConfigService } from './hvita-config.service';
import { HVitaColorService } from './hvita-color.service';

/**
 * Gestiona los colores de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaParameterService extends HVitaParameterStudentService
{
  constructor(socketService: HVitaSocketService,
              configService: HVitaConfigService,
              modalService: ModalService,
              eventManagerService: EventManagerService,
              colorToNamePipe: HVitaColorToNamePipe,
              colorService:HVitaColorService)
  {
    super(eventManagerService, colorToNamePipe, modalService, configService, colorService,  socketService);
  }
}
