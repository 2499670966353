import { Injectable } from "@angular/core";
import { HVitaColorBaseService } from 'hvita-color';
import { Color } from "hvita-common";
import { HVitaSocketService } from "./hvita-socket.service";
import { SocketAction } from "hybrids-socket";;
import { HVitaConfigService } from "./hvita-config.service";

/**
 * Gestiona los colores de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaColorService extends HVitaColorBaseService
{
  constructor(private socketService:HVitaSocketService,
              private configService:HVitaConfigService)
  {
    super();
  }

  getColors(): Promise<Color[]>
  {
    return this.socketService.sendAndRetrive(SocketAction.COLORS_LIST, this.socketService.addresses.teacher);
  }
}
