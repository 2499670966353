import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HybridsSocketListenerService,
         SocketAction,
         SocketMessage,
         SocketAddress} from 'hybrids-socket';
import { Device, ErrorType } from 'hybrids-config';
import { EventManagerService } from 'event-manager';
import { HVitaStudentEvent } from '../enums/hvita-event.enum';
import { HVitaLanguageService } from './hvita-language.service';
import { HVitaConfigService } from './hvita-config.service';
import { HVitaIpcService } from './hvita-ipc.service';
import { HVitaSimulationService } from './hvita-simulation.service';
import { HVitaParameterService } from './hvita-parameter.service.';
import { HVitaTemplateService } from './hvita-template.service';
import { HVitaSocketService } from './hvita-socket.service';
import { HVitaTestsService } from './hvita-tests.service';
import { HVitaTestsEvent } from 'hvita-tests';
import { PatientMonitorService } from './patient-monitor.service';
import { Network } from 'network';
import { HVitaScenarioEvent } from 'hvita-scenario';
import { PatientMonitorWaveService } from './patient-monitor-wave.service';

/**
 * Gestiona las conexiones al socket service
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaSocketListenerService extends HybridsSocketListenerService
{
  constructor(private router: Router,
              private languageService:HVitaLanguageService,
              private configService:HVitaConfigService,
              private ipcService:HVitaIpcService,
              private simulationService:HVitaSimulationService,
              private parameterService:HVitaParameterService,
              private templateService:HVitaTemplateService,
              private testService:HVitaTestsService,
              private monitorService:PatientMonitorService,
              private waveService:PatientMonitorWaveService,
              socketService:HVitaSocketService,
              eventManagerService: EventManagerService)
  {
    super(eventManagerService, socketService);

    this.eventManagerService.addEvent(HVitaStudentEvent.INITIALIZE_STUDENT);
  }

  /**
   * Establece las acciones a realizar cuando se recibe una acción del socket
   */
  public setSocketReceived(request: SocketMessage): void
  {
    switch(request.action)
    {
      //un dispositivo ha sido conectado
      //no es necesaria una respuesta
      //pasamos directamente el dispositivo
      case SocketAction.ENABLED_DEVICE :
        this.addDevice(request.data);
        break;

      //un dispositivo ha sido desconectado
      //no es necesaria una respuesta
      //pasamos directamente el dispositivo
      case SocketAction.DISABLED_DEVICE :
        this.removeDevice(request.data);
        break;

      //Teacher envia un cambio de idioma
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.LANGUAGE :
        this.languageService.setEnabledLanguage(request.data);
        break;

      //Teacher envia un cambio de modo de interacción
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.INTERACTIVE_MODE :
        this.configService.interactiveMode = request.data;
        break;

      //Teacher envia un cambio de modo de Ecmo
      //pasamos directamente los datos
      case SocketAction.ECMO_MODE :
        this.configService.ecmoMode = request.data;
        break;

      //Teacher envia un cambio de modo de ventilación
      //pasamos directamente los datos
      case SocketAction.SET_VENTILATION_MODE :
        this.simulationService.setVentilationMode(request.data);
        break;

      //Teacher envia los datos de una simulación
      //puede ser por inicio de la misma o por cambio de escenario
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.STAGE :
        this.simulationService.loadStage(request.data);
        break;

      //Teacher envia un parámetro
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.PARAMETER :
        this.parameterService.setParameter(request.data);
        break;

      //Teacher envia un orden de resetear todos los parametros
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.RESET_ALL :
        this.parameterService.resetAllParameters();
        break;

      //Teacher envia órden de abrir una imagen o vídeo
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.OPEN_IMAGE :
      case SocketAction.OPEN_VIDEO :
        this.testService.openViewer(request.data);
        break;

      //Teacher envia órden de cerrar una imagen o vídeo
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.CLOSE_IMAGE :
      case SocketAction.CLOSE_VIDEO :
        this.testService.closeViewer();
        break;

      //Teacher envia órden de reproducir o pausar un vídeo
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.PLAY_VIDEO :
      case SocketAction.PAUSE_VIDEO :
        this.eventManagerService.emitEvent(HVitaTestsEvent.TOGGLE_VIDEO, request.action);
        break;

      //Teacher envia órden de aumentar o disminuir el zoom de la imagen
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.IMG_ZOOM_IN :
      case SocketAction.IMG_ZOOM_OUT :
        this.eventManagerService.emitEvent(HVitaTestsEvent.IMG_ZOOM, { type: (request.action == SocketAction.IMG_ZOOM_IN) ? 'in' : 'out', rect: request.data });
        break;

      //Teacher envia órden de aumentar o disminuir el zoom de la imagen
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.IMG_MOVE :
        this.eventManagerService.emitEvent(HVitaTestsEvent.IMG_MOVE, request.data);
        break;


      //Teacher envia un valor real del monitor de panciente
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.PATIENT_MONITOR_SET_VALUE :
        this.waveService.setRealValue(request.data);
        break;

      //Teacher envia órden de modificar el ritmo del monitor de panciente
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.CHANGE_RHYTHM :
        this.monitorService.changeListValues(request.data);
        break;

      //Teacher envia órden de modificar las vistas del monitor de panciente
      //no es necesaria una respuesta
      //pasamos directamente los datos
      case SocketAction.CHANGE_PATIENT_VIEW :
        this.monitorService.changePatientViews(request.data);
        break;

      case SocketAction.RHYTHMS_LIST:
        this.monitorService.setRhytmsList(request.data);
        break;

      case SocketAction.PATIENT_VIEW_LIST:
        this.monitorService.setPatientViewList(request.data);
        break;

      //Teacher envia órden de pausar el monitor de panciente
      //no es necesaria una respuesta
      case SocketAction.PAUSE_MONITOR :
        this.monitorService.pause();
        break;

      //Teacher envia órden de reanudar el monitor de panciente
      //no es necesaria una respuesta
      case SocketAction.RESUME_MONITOR :
        this.monitorService.resume();
        break;

      //Teacher envia órden de mutear las alarmas
      //no es necesaria una respuesta
      case SocketAction.MUTE :
        this.eventManagerService.emitEvent(HVitaScenarioEvent.MUTE);
        break;

      //Teacher envia órden de desmutear las alarmas
      //no es necesaria una respuesta
      case SocketAction.UNMUTE :
        this.eventManagerService.emitEvent(HVitaScenarioEvent.UNMUTE);
        break;

      //Teacher envia órden de reproducir una alarma
      //no es necesaria una respuesta
      case SocketAction.START_ALARM :
        this.simulationService.startAlarm();
        break;

      //Teacher envia órden de detener una alarma
      //no es necesaria una respuesta
      case SocketAction.STOP_ALARM :
        this.simulationService.stopAlarm();
        break;

      //Teacher envia órden de reproducir un sonido de aviso
      //no es necesaria una respuesta
      case SocketAction.START_ATENTION :
        this.simulationService.startAtention();
        break;

      //Teacher envia órden de detener un sonido de aviso
      //no es necesaria una respuesta
      case SocketAction.STOP_ATENTION :
        this.simulationService.stopAtention();
        break;

      //Teacher envia estado del mute de alarmas
      //no es necesaria una respuesta
      case SocketAction.MUTE_STATUS :
        this.eventManagerService.emitEvent((request.data) ? HVitaScenarioEvent.MUTE : HVitaScenarioEvent.UNMUTE);
        break;

      //Teacher envía la url del servidor web
      case SocketAction.WEB_SERVER_TEACHER :
        this.ipcService.storageURL = request.data;
        break;

      //Teacher envia la lista de plantillas
      //pasamos toda la peticion para poder responder
      case SocketAction.TEMPLATES_LIST :
        this.templateService.setTemplateList(request.data);
        break;
    }
  }

  /**
   * Establece las acciones a realizar durante la desconexión del socket
   */
  protected setSocketDisconnected(): void
  {
    //deshabilitamos teacher
    this.configService.teacherEnabled = false;

    //mostramos la pantalla de error
    if(this.hybridsSocketService.redirect)
    {
      const route = [ '/', 'error', (this.hybridsSocketService.enabledConnection) ? ErrorType.TEACHER : ErrorType.SOCKET ];
      this.router.navigate(route);
    }
  }

  /**
   * Comprueba los dispositivos conectados al socket service
   */
  protected getConnectedDevices(): void
  {
    this.hybridsSocketService.sendAndRetrive(SocketAction.ENABLED_DEVICES_LIST, this.hybridsSocketService.addresses.server).then((response:any) =>
    {
      //guardamos la lista de dispositivos conectados
      this.devices = (response.data instanceof Array) ? response.data as SocketAddress[] : new Array<SocketAddress>();

      if(this.devices.find(m => m.device === Device.TEACHER))
      {
        if(!this.configService.teacherEnabled)
        {
          setTimeout(() =>
          {
            this.configService.teacherEnabled = true;
            this.eventManagerService.emitEvent(HVitaStudentEvent.INITIALIZE_STUDENT, true);
          },
          1000);
        }
      }
      else
      {
        this.setSocketDisconnected();
      }
    }).catch(err => console.log('error recived', err));
  }

  /**
   * Establece las acciones a realizar después de añadir un dispositivo
   * @param device dispositivo añadido
   */
  protected setAddDevice(device: Device): void
  {
    if(device == Device.TEACHER && !this.configService.teacherEnabled)
    {
      this.configService.teacherEnabled = true;
      this.eventManagerService.emitEvent(HVitaStudentEvent.INITIALIZE_STUDENT, true);
    }
  }

  /**
   * Establece las acciones a realizar después de eliminar un dispositivo
   * @param device dispositivo eliminado
   */
  protected setRemoveDevice(device: Device): void
  {
    if(device == Device.TEACHER)
      this.setSocketDisconnected();
  }

  protected setRetryConnection()
  {
    Network.getNetworkStatus().then(status =>
    {
      if(status)
        this.hybridsSocketService.timeOutRetry = setTimeout(() => this.hybridsSocketService.openConnection(), this.hybridsSocketService.interval);
    });
  }
}
