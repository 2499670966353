import { registerPlugin } from '@capacitor/core';

import type { InfoPlugin } from './definitions';

/**
 * Registra el plugin
 */
const Info = registerPlugin<InfoPlugin>('Info', {
  web: () => import('./web').then(m => new m.InfoWeb()),
  electron: () => (window as any).CapacitorCustomPlatform.plugins.Info,
});

export * from './definitions';
export { Info };
