export interface ManageFilePlugin 
{
  /**
   * Comprueba si existen los directorios que necesita la aplicación para funcionar correctamente.
   * En caso de que no existan los crea.
   * @param directories directorios
   * @returns promesa
   */
  checkDirectories(directories:string[][]): Promise<void>;

  /**
   * Escribe un fichero de texto
   * @param content Contenido del fichero
   * @param device Letra de la unidad en la que se grabará el fichero
   * @param name Nombre del fichero
   * @returns promesa
   */
  save(content: string, device: string, name: string): Promise<void>;

  /**
   * Mueve un fichero de una ubicación a otra
   * @param from Ubicación del fichero que se quiere mover (incluido nombre y extensión)
   * @param to Ubicación a la que se moverá el fichero
   * @param name Nombre del fichero
   * @returns promesa
   */
  move(from: string, to: string): Promise<void>;

  /**
   * Ubicación del fichero que se quiere eliminar (incluido nombre y extensión)
   * @returns promesa
   * @param path 
   */
  delete(path: string): Promise<void>;

  /**
   * Muestra un cuadro de diálogo de selección de ficheros
   * @param device Letra de la unidad que se abrirá por defecto en el selector de ficheros
   * @returns promesa
   */
  select(device: string): Promise<any>;

  /**
   * Devuelve el path físico de la carpeta de almacenamiento
   * @returns url string
   */
  getStoragePath(): Promise<string>;
}