
import { ElectronService } from 'ngx-electron';
import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppBase, ErrorType } from 'hybrids-config';
import { HVitaScenarioService } from './services/hvita-scenario.service';
import { HVitaLanguageService } from './services/hvita-language.service';
import { VersionBoxService } from 'version-box';
import { HVitaIpcService } from './services/hvita-ipc.service';
import { HVitaConfigService } from './services/hvita-config.service';
import { HVitaSocketListenerService } from './services/hvita-socket-listener.service';
import { HVitaSocketService } from './services/hvita-socket.service';
import { Network } from 'network';
import { HVitaTemplateService } from './services/hvita-template.service';
import { SocketAction } from 'hybrids-socket';
import { HVitaColorService } from './services/hvita-color.service';
import { EventManagerService } from 'event-manager';
import { HVitaStudentEvent } from './enums/hvita-event.enum';
import { SectionType, VentilationMode } from 'hvita-common';
import { HVitaSimulationService } from './services/hvita-simulation.service';
import { HVitaTestsService } from './services/hvita-tests.service';
import { PatientMonitorService } from './services/patient-monitor.service';
import { PatientMonitorWaveService } from './services/patient-monitor-wave.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { HVitaParameterService } from './services/hvita-parameter.service.';
import { HVitaScenarioEvent } from 'hvita-scenario';

/**
 * Componente que representa la base de la aplicación
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends AppBase
{
  environment = environment;

  socketInitialized = false;
  appInitialized = false;

  constructor(public electronService:ElectronService,
              private configService:HVitaConfigService,
              private languageService:HVitaLanguageService,
              private ipcService:HVitaIpcService,
              private versionBoxService:VersionBoxService,
              private socketService:HVitaSocketService,
              private socketListenerService:HVitaSocketListenerService,
              private templateService:HVitaTemplateService,
              private colorService:HVitaColorService,
              private eventManagerService:EventManagerService,
              private simulationService:HVitaSimulationService,
              private testsService:HVitaTestsService,
              private monitorService:PatientMonitorService,
              private waveService:PatientMonitorWaveService,
              private parameterService:HVitaParameterService,
              private router:Router,
              private scenarioService:HVitaScenarioService,
              @Inject(DOCUMENT) doc:Document)
  {
    super(doc);
  }

  /**
   * Ejecuta los procesos de inicialización de la aplicación
   * y añade las subscripciones necesarias para responder a
   * diversas acciones emitidas desde distintos componentes
   */
  async initialize()
  {
    this.eventManagerService.subscribeEvent(HVitaStudentEvent.INITIALIZE_STUDENT, () =>
    {
      this.socketInitialized = true;

      if(this.appInitialized)
        this.initializeStudent();
    });

    try
    {
      await this.languageService.initialize();

      await this.ipcService.initialize();

      this.versionBoxService.initialize(this.ipcService.info.software, this.ipcService.info.hardware);

      this.configService.initialize();

      await this.scenarioService.initialize();

      this.templateService.initialize();

      this.testsService.initialize();

      //inicializamos el socket y el listener
      this.socketService.initialize(environment.endPointSocket, environment.device);
      this.socketListenerService.initialize();

      //iniciamos la conexion a red wifi y socket
      await Network.initialize(this.ipcService.info.ssid!, this.ipcService.info.password!, this.ipcService.localStorageWireless);

      if(this.electronService.isElectronApp) //en aplicaciones electron iniciamos el checkeo de redes
        await Network.check();
      else //en aplicaciones web abrimos la conexión socket
        this.socketService.openConnection();

      this.appInitialized = true;

      if(this.socketInitialized)
        this.initializeStudent();
    }
    catch(err)
    {
      this.router.navigate([ '/', 'error', ErrorType.UNKNOW ])
    }
  }

  /**
   * Inicializa Student cargando los datos necesarios de Teacher
   */
  initializeStudent()
  {
    //obtenemos el idioma actual seleccionado en el profesor
    this.socketService.sendAndRetrive(SocketAction.LANGUAGE, this.socketService.addresses.teacher).then(lang =>
    {
      this.languageService.setEnabledLanguage(lang.data);

      this.socketService.sendAndRetrive(SocketAction.COLORS_LIST, this.socketService.addresses.teacher).then(colors =>
      {
        this.colorService.colors = colors.data;

        this.socketService.sendAndRetrive(SocketAction.TEMPLATES_LIST, this.socketService.addresses.teacher).then(templates =>
        {
          templates.data.map(template =>
          {
            const preasure = template.templatesSections.find(m => m.idSection == SectionType.ECMO_PREASURE);
            const flow = template.templatesSections.find(m => m.idSection == SectionType.ECMO_FLOW);

            flow.visible = (!preasure.visible && !flow.visible) ? false : true;

            template.templatesSections.splice(template.templatesSections.findIndex(m => m.idSection == SectionType.ECMO_PREASURE), 1);
            template.templatesSections.splice(template.templatesSections.findIndex(m => m.idSection == SectionType.CONTROLLER), 1);
          });

          this.templateService.templates = templates.data;
          this.templateService.template = templates.data.find(m => m.isDefault).cloneObject();

          //cargamos la configuración de plantilla
          this.templateService.loadTemplates().then(() =>
          {
            this.simulationService.initialize();
            this.parameterService.initialize();

            this.monitorService.initialize(this.configService.config.distribution.find(m => m.section === SectionType.MONITOR).keys[0]).then(async ()  =>
            {
              this.socketService.sendAndRetrive(SocketAction.WAVES_LIST, this.socketService.addresses.teacher).then(waves =>
              {
                this.monitorService.waves = waves.data;

                this.socketService.sendAndRetrive(SocketAction.DAMPINGS_LIST, this.socketService.addresses.teacher).then(dampings =>
                {
                  this.monitorService.dampings = dampings.data;

                  this.socketService.sendAndRetrive(SocketAction.RHYTHMS_LIST, this.socketService.addresses.teacher).then(rhythms =>
                  {
                    this.monitorService.rhythms = rhythms.data.rhythms;
                    this.monitorService.rhythmsOptions = rhythms.data.options;
                    this.monitorService.selectRhythm = rhythms.data.selected;

                    this.socketService.sendAndRetrive(SocketAction.PATIENT_VIEW_LIST, this.socketService.addresses.teacher).then(views =>
                    {
                      this.monitorService.views = views.data.views;
                      this.monitorService.selectViews = views.data.selected;

                      this.socketService.sendAndRetrive(SocketAction.DERIVATION_VARIABLES_LIST, this.socketService.addresses.teacher).then(derivations =>
                      {
                        this.monitorService.derivationVariables = derivations.data;

                        //inicializamos las curvas
                        this.waveService.initialize();

                        this.socketService.sendAndRetrive(SocketAction.ECMO_MODE, this.socketService.addresses.teacher).then(ecmo =>
                        {
                          this.configService.ecmoMode = ecmo.data;

                          this.socketService.sendAndRetrive(SocketAction.INTERACTIVE_MODE, this.socketService.addresses.teacher).then(mode =>
                          {
                            this.configService.interactiveMode = mode.data;

                            this.socketService.sendAndRetrive(SocketAction.WEB_SERVER_TEACHER, this.socketService.addresses.teacher).then(url =>
                            {
                              this.ipcService.storageURL = url.data;

                              //obtenemos la configuración del muteado de alarmas
                              this.socketService.sendAndRetrive(SocketAction.MUTE_STATUS, this.socketService.addresses.teacher).then(muted =>
                              {
                                this.eventManagerService.emitEvent((muted.data) ? HVitaScenarioEvent.MUTE : HVitaScenarioEvent.UNMUTE);

                                this.socketService.sendAndRetrive(SocketAction.GET_VENTILATION_MODE, this.socketService.addresses.teacher).then(vent =>
                                {
                                  this.configService.config.ventilationMode = vent.data;

                                  //preguntamos al profesor si esta reproduciendo alguna simulacion
                                  this.socketService.sendAndRetrive(SocketAction.STAGE, this.socketService.addresses.teacher).then(parameters =>
                                    this.simulationService.loadStage(parameters.data));
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }
}
