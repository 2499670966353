import { Injectable } from "@angular/core";
import { EventManagerService } from "event-manager";
import { HVitaTestsBaseService } from "hvita-tests";
import { HVitaConfigService } from "./hvita-config.service";
import { HVitaIpcService } from "./hvita-ipc.service";
import { TestType } from "hvita-common";


/**
 *
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaTestsService extends HVitaTestsBaseService
{
  constructor(configService: HVitaConfigService,
              eventManagerService: EventManagerService,
              private ipcService:HVitaIpcService)
  {
    super(configService, eventManagerService)
  }

  getFile()
  {
    return new Promise<string>((resolve) =>
    {
      const uri = this.ipcService.storageURL + '/' + ((this.testSelected?.idTestType === TestType.IMAGE) ? 'images' : 'videos') + '/' + this.testSelected?.file;
      resolve(uri);
    });
  }

  playVideo() {}
  pauseVideo() {}
}
