import { Injectable } from "@angular/core";
import { WaveStudentService } from "patient-monitor";
import { PatientMonitorService } from "./patient-monitor.service";
import { EventManagerService } from "event-manager";

/**
 * Gestiona las pruebas complementarias que se muestran en una simulación
 */
@Injectable({
  providedIn: 'root'
})
export class PatientMonitorWaveService extends WaveStudentService
{
  constructor(monitorService: PatientMonitorService,
              eventManagerService:EventManagerService)
  {
    super(monitorService, eventManagerService);
  }
}
