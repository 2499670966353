import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HVitaConfig } from 'hvita-common';
import { HVitaLanguageStudentService } from 'hvita-language';
import { HVitaConfigService } from './hvita-config.service';

/**
 * Gestiona los colores de los parámetros de simulación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaLanguageService extends HVitaLanguageStudentService
{
  constructor(configService:HVitaConfigService,
              translateService:TranslateService)
  {
    super(configService, translateService);
  }
}
